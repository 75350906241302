import React from "react"
import Layout from "../components/layout"

export default function Pets() {
  return (
    <Layout>
      <section className="page">
        <h1 className="page__heading">Pet Policy</h1>
        <div className="page__list">
          <ul className="page__body">
            <li className="page__list__body">
              Dogs over the age of 1 year are permitted
            </li>
            <li className="page__list__body">There is no additional pet fee</li>
            <li className="page__list__body">Cats are not permitted</li>
          </ul>
          <p className="page__list__body page__list__body--no-symbol">
            For your pets safety and well being we strongly encourage against
            ever leaving your pet unaccompanied inside the motorhome. GS
            VanLife, Inc will not be help responsible for any harm, injury or
            loss you or your pet may suffer as a result of being left alone
            inside the motorhome.
          </p>
        </div>
      </section>
    </Layout>
  )
}
